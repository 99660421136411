import { withFirebaseId } from '../../utils/firebase.utils';
import {
  Option,
  ListItemsPickerCellEditorParams,
  ListItemsPickerCellEditorComponent,
} from './list-items-picker-cell-editor.component';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { ColDef } from '@ag-grid-community/core';

export async function getListItemsPickerColumnDef(
  optionsCollection: AngularFirestoreCollection,
  params: Partial<Omit<ListItemsPickerCellEditorParams, 'options'>> = {},
  ): Promise<ColDef> {
  const optionsSnapshot = await optionsCollection.get().toPromise();

  const menus: any[] = optionsSnapshot.docs.map(withFirebaseId);
  const menuOptions = menus.map<Option>((item) => ({label: item.name, value: item.id}));

  return getColDef(
    menuOptions,
    params,
  );
}

function getLabelByEntityId(options: Option[], id: string): string {
  const el = options.find((option) => option.value === id);
  return el ? el.label : '';
}

function getColDef(options: Option[], cellEditorParams: Partial<ListItemsPickerCellEditorParams> = {}): ColDef {
  return {
    editable: true,
    cellEditorFramework: ListItemsPickerCellEditorComponent,
    valueFormatter: (params) => {
      if (cellEditorParams.multi) {
        const ids = params.value as string[] || [];
        return ids.map((id) => getLabelByEntityId(options, id)).join(', ');
      } else {
        const id = params.value as string || '';
        return getLabelByEntityId(options, id);
      }
    },
    cellEditorParams: {
      options,
      ...cellEditorParams,
    } as ListItemsPickerCellEditorParams,
  };
}
